import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/fool.webp'
import Graph from '../components/graph'
import Mail from '../components/mail'
import MyVideo from '../components/video'
import { Link } from "gatsby"
import More from '../components/more'
class Result extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);


    this.state = this.props.location.state;


  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Fool";
    return (
      <Layout>
        <Helmet title="Fool (Jester)" />
        <HeaderGeneric text="Fool (Jester)" subtext="Lives in the moment"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>


            <h2>Fool (Jester)</h2>
            
            <p>The Fool archetype, sometimes also called the Jester, is a powerful force that can control our lives if we do not take heed. This archetype urges us to embrace the joy and playfulness of life, but it can also lead to laziness and dissipation. The positive Jester invites us to turn even the most mundane tasks into fun, but it can also lead to a lack of motivation and a lack of purpose. The goal of the Jester is to enjoy life as it is, but this can also lead to a lack of meaning and purpose in our lives.
The Jester archetype can be a double-edged sword, and if we do not integrate it properly, it can slowly ruin our relationships and our lives. It is important to understand that the Jester archetype is not just about having fun, but about finding meaning and purpose in our lives. To truly master the Jester archetype, we must understand the underlying wisdom that it represents.
If you are struggling with the Jester archetype and want to learn more about how to integrate it into your life, I recommend reading our e-book, <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=trickster"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> This e-book will help you understand the Jester archetype and how to use it to create a more fulfilling and meaningful life. Remember, the Jester archetype is a powerful force, and if we do not take control of it, it will control us.

</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
